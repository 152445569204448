//import Pix from './images/avatar.jpg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimony(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000

    }
    return(
        <div className="testimony-main">
        <div className="row p-5">
            <div className="col-sm-6 p-5 testimony">
                <br />
                <button type="button" className="btn btn-white boxshadow bg-white p-2">Testimonies</button>
                <h2>Here Is What Our <span>Customers</span> Are Saying About Us.</h2>
                <p>What our customers are saying about us. We are customer focused and do everything to make them satisfied and happy.</p>
            </div>

            <div className="col-sm-6 p-5">
                <Slider {...settings}>
                    {data.map((d) => (
                    <div className="boxshadow bg-white mb-3 mr-3 mt-3 p-5">
                        <div className="row">
                            <div className=" col-sm-12 p-3" style={{fontSize: "25px", textAlign:"justify"}}>
                                <p><i>{d.review}</i></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <img src={d.img} alt="customer testimonial" className="bg-white" style={{borderRadius: "50%", width: "100%", border: "5px solid blue", padding:"5px"}} />
                            </div>
                            <div className="col-sm-4">
                                <p className="pname">{d.name}</p>
                            </div>
                        </div>
                    </div>
        
                    ))}
                </Slider>
            </div>
            
        </div>
        </div>
    );
}
const data = [
    {
        name: "John Doe",
        img: "/images/avatar.png",
        review: "\"I have been using Travel Solutions as my agent anytime I am embarking on a trip. I must confess the experience with these guys have been awesome.\""
    },
    {
        name: "Adewale Kazeem",
        img: "/images/avatar.png",
        review: "\"I have been using Travel Solutions as my agent anytime I am embarking on a trip. I must confess the experience with these guys have been awesome.\""
    },
    {
        name: "Donald Chukwuemeka",
        img: "/images/avatar.png",
        review: "\"I have been using Travel Solutions as my agent anytime I am embarking on a trip. I must confess the experience with these guys have been awesome.\""
    },
    {
        name: "Mary Coker",
        img: "/images/avatar.png",
        review: "\"I have been using Travel Solutions as my agent anytime I am embarking on a trip. I must confess the experience with these guys have been awesome.\""
    },
    {
        name: "Adamu Muhammed",
        img: "/images/avatar.png",
        review: "\"I have been using Travel Solutions as my agent anytime I am embarking on a trip. I must confess the experience with these guys have been awesome.\""
    }
];
export default Testimony;