import Office from '../images/family5.jpg';

export default function Mainservices(){
    return(
        <div className="row bg-primary" style={{marginTop: "25px"}}>
            
            <div className="col-sm-6 p-5">
                <h2 className="pb-5" style={{fontSize:"50px", textAlign:"center", fontWeight:"700",color:"white"}}>Our Travel Window</h2>
                <ul className="serve" style={{fontFamily: "cursive", color:"white", fontSize: "30px", fontWeight:"700", textDecoration:""}}>
                    <li>Affordable flight reservations</li>
                    <li>Sales of all airlines tickets</li>
                    <li>Worldwide budget friendly hotel</li>
                    <li>Travel plans and consulting</li>
                    <li>Passport and Visa consulting</li>
                    <li>Medical Travel Insurance</li>
                    <li>Air Charter Services</li>
                    <li>Tour packages</li>
                </ul>
            </div>

            <div className="col-sm-6">
                <img src={Office} alt="Our Services" width="100%" />
            </div>

        </div>
    );
}