
export default function Servicedesc(){
    return(
        <div style={{padding: "50px"}}>
            <h3>Exceptional Customer Support</h3>
            <p> 
            When you book with us, you are not just making a reservation; you are gaining access to expert guidance, quick responses, and tailored assistance. With our 24/7 support, we are always available, just a click or a call away, ensuring your travel experience is seamless from start to finish. 
            </p>

            <h3>Unbeatable Savings</h3>
            <p>  
            With us, discovering the world doesn’t have to be expensive. We’re committed to offering incredible deals and exclusive discounts, so you can make the most of every journey without stretching your budget.
            </p>

            <h3>Safety First</h3>
            <p>  
            From the moment you book to the moment you return, Travel Solutions Ltd is dedicated to providing a safe and secure travel experience. Our carefully designed safety protocols, trusted partners, and robust support system are in place to ensure your peace of mind every step of your journey.
            </p>

            <h3>Trusted by Travelers Everywhere</h3>
            <p> 
            Our commitment to exceptional service, attention to detail, and customer satisfaction has earned us the trust of countless travelers. At Travel Solutions, we strive to be your go-to travel agency, offering reliable solutions for every type of trip. We certainly give you value for your money.
            </p>
        </div>
    );
}